import React, { useState, useEffect } from 'react';
import './ProBolivia.css';
import logoconsume from '../../img/consume.png';
const ProBolivia = () => {
    const [productos, setProductos] = useState([]);
    const [isTop, setIsTop] = useState(false);
    const [search, setSearch] = useState("");
    const getProductosProBolivia = async () => {
        try {
            const res = await fetch("api/ketalweb/getProductosProBolivia");
            const data = await res.json();
            if (res.status == 200) {
                setProductos(data);
            }
            else {
                console.log("Error getProductosProBolivia: " + res.status);
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getProductosProBolivia();
    }, [productos]);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsTop(true);
            } else {
                setIsTop(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className="container-pro">
            <img className="img-background-pro" src={logoconsume} />
            <div className="table-container-pro">
                <table className="table-pro">
                    <thead>
                        <tr style={{ height: '30px', borderBottom: 'none' }} className={`fixed-item ${isTop ? 'top' : ''}`}>
                            <th className="th-pro">
                                <input placeholder="PRODUCTOS PRO-BOLIVIA" className="input-search-pro"
                                    value={search} onChange={(e) => setSearch(e.target.value)}>
                                 
                                </input>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {productos.length > 0 && productos.filter(s => s.toUpperCase().includes(search.toUpperCase())).map((item, key) => {
                            return (
                                <tr>
                                    <td>{item}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ProBolivia;