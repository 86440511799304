import Home from "./components/Home";
import Ofertas from "./components/Ofertas";
import PayConirmation from "./components/PayConfirmation/index";
import Pedidos from './components/Pedidos';
import TerminosDelivery from './components/TerminosDelivery';
import ProBolivia from "./components/ProBolivia/index";
import Reporte from './components/ReporteGiftcard/Reporte';

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: "/ofertas",
        element: <Ofertas/>
    },
    {
        path: "/pedidos",
        element: <Pedidos/>
    },
    {
        path: "/terminos-y-condiciones-delivery",
        element: <TerminosDelivery/>
    },
    {
        path: "/probolivia",
        element: <ProBolivia/>
    },
    { path: '/reporteGiftcards', element:< Reporte />}
];

export default AppRoutes;
